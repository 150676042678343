import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const auth0Options = {
  audience: process.env.GATSBY_AUTH0_AUDIENCE,
  scope: process.env.GATSBY_AUTH0_SCOPE,
};

const EmailPopup = () => {
  const [verificationInfoVisible, setVerificationInfoVisible] = React.useState(false);
  const {
    user,
    isAuthenticated,
    isLoading,
    getIdTokenClaims,
  } = useAuth0();

  const loadAccountInfo = React.useCallback(async () => {
    if (!isAuthenticated) {
      return;
    }


    const claims = await getIdTokenClaims(auth0Options);
    const emailVerifiedClaimValue: boolean = !!claims?.email_verified
    setVerificationInfoVisible(!emailVerifiedClaimValue);
  }, [isAuthenticated]);

  React.useEffect(() => {
    console.log('useEffect');
    loadAccountInfo().catch(console.error);
  }, [loadAccountInfo]);

  return (
    <div>
      {!isLoading && isAuthenticated && verificationInfoVisible && (
        <div className='email-popup'>
          <p>
            Please verify your e-mail address <span> {user?.email}</span>
          </p>
        </div>
      )}
    </div>
  );
}

export default EmailPopup;
