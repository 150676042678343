import React, { useState } from 'react';

interface Props {
  usedClassName: string;
  usedButtonClassName?: string;
  dataForm: string;
}

const RegistrationForm = ({ usedClassName, usedButtonClassName, dataForm }: Props) => {
  return (
    <div className={usedClassName}>
      <div className="ml-embedded" data-form={dataForm}></div>
    </div>
  );
};

export default RegistrationForm;
