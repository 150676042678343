import * as React from 'react';

// UI element to open Cookies preferences
// window.cookieconsent is set in the DOM by TermsFeed CMP
// see <head> and <body> in html.js
const CookiePreferences = () => {
    const handleOpenPreferencesCenter = () => {
      const cookieConsent = (window as any).cookieconsent;
          
      if (cookieConsent && cookieConsent.openPreferencesCenter) {
        cookieConsent.openPreferencesCenter();
      }
    };
  
    return (
      <p onClick={handleOpenPreferencesCenter}>
        Update Cookie Preferences
      </p>
    );
};

export default CookiePreferences;
