import { graphql, Link, useStaticQuery, withPrefix } from 'gatsby';
import * as React from 'react';
import { useScriptFromString } from '../hooks/useScript'
import CookiePreferences from './cookie-preferences'
interface PageQueryResult {
  latestTutorials: {
    nodes: Post[];
  };
  latestBlogPosts: {
    nodes: Post[];
  };
}

interface Post {
  fields: {
    slug: string;
  };
  frontmatter: {
    title: string;
  };
}

const MailerliteOptions = {
  accountId: process.env.GATSBY_MAILERLITE_ACCOUNTID || "no-id",
  formHash: process.env.GATSBY_MAILERLITE_FORMHASH || "no-hash",
};

const mailerLiteScript : string = 
`(function(w,d,e,u,f,l,n){w[f]=w[f]||function(){(w[f].q=w[f].q||[])
  .push(arguments);},l=d.createElement(e),l.async=1,l.src=u,
  n=d.getElementsByTagName(e)[0],n.parentNode.insertBefore(l,n);})
  (window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
  ml('account', 'ACCOUNTID');`

function Footer() {
  useScriptFromString(mailerLiteScript.replace('ACCOUNTID',MailerliteOptions.accountId))

  const data: PageQueryResult = useStaticQuery(graphql`
    query FooterPosts {
      latestTutorials: allMarkdownRemark(
        filter: { frontmatter: { feed: { eq: "tutorials" } } }
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 8
      ) {
        nodes {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
      latestBlogPosts: allMarkdownRemark(
        filter: { frontmatter: { feed: { eq: "blog" } } }
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 3
      ) {
        nodes {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  `);
  return (
    <footer id="footer">
      <div className="container">
        <div className="footer-inner wow pixFadeUp">
          <div className="row">
            <div className="col-md-2">
              <div className="widget footer-widget">
                <h3 className="widget-title">Getting Started</h3>

                <ul className="footer-menu">
                <li><Link to="/getting-started">Let's start!</Link></li>
                  {/* <li><Link to="/tutorials/gs_00_about_asciidoc">About Skillgo</Link></li>
                  <li><Link to="/tutorials/gs_01_editor">The Editor</Link></li>
                  <li><Link to="/tutorials/gs_02_hello_world">Hello World</Link></li>
                  <li><Link to="/tutorials/gs_03_basic_text_formatting">Adding your First Texts</Link></li>
                  <li><Link to="/tutorials/gs_04_images">Adding your First Images</Link></li>
                  <li><Link to="/tutorials/gs_05_sections">Adding New Chapters</Link></li>
                  <li><Link to="/tutorials/gs_06_quiz">Adding a Quiz Block</Link></li>
                  <li><Link to="/tutorials/gs_07_publishing">Publishing your Course</Link></li> */}
                </ul>
              </div>
            </div>

            <div className="col-md-2">
              <div className="widget footer-widget">
                <h3 className="widget-title">Tutorial</h3>
                <ul className="footer-menu">
                <li>
                
                  <a href={withPrefix('tutorial/en/dashboard')} target="_blank">
                    Check it!                       
                  </a>
                </li>
                </ul>
              </div>
            </div>

            <div className="col-md-3">
              <div className="widget footer-widget">
                <h3 className="widget-title">Latest Blog Posts</h3>

                <ul className="footer-menu">
                  {data.latestBlogPosts.nodes.map(post => (
                    <li key={post.fields.slug}>
                      <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="col-md-3">
              <div className="widget footer-widget">
                <h3 className="widget-title">Contact</h3>

                <ul className="footer-menu">
                  <li>
                    <Link className='contact' partiallyActive={true} to="/contact/">
                      Contact Us!
                    </Link>
                  </li>
                  
                  <li>info@skillgo.io</li>
                  <li>
                    <p>Hársfa utca 122., <br />1162 Budapest, Hungary</p>
                  </li>
                </ul>

                <ul className="footer-social-link">
                  {/* <li>
                    <a href="https://www.facebook.com/skillgoapp" target="_blank">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li> */}
                  {/*<li>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-google-plus-g"></i>
                    </a>
                  </li>*/}
                  {/* <li>
                    <a href="https://www.linkedin.com/company/skillgo/" target="_blank">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>

            <div className="col-md-2">
              <div className="widget footer-widget">
                <h3 className="widget-title">With love from</h3>
                <ul className="footer-menu">
                  <li>
                    <a href="https://www.neting.hu/" target="_blank">Neting Informatika Ltd.</a>
                  </li>
                </ul>
                <ul className="footer-social-link">
                  <li>
                    <a href="https://www.facebook.com/NetingInformatika" target="_blank" >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/neting-informatika-ltd/" target="_blank">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mailerlite-wrapper">
          <div className="col-md-5">
            <div className="ml-embedded" data-form={MailerliteOptions.formHash}>
            </div>
          </div>
          <div className="col-md-7">
            <h2 className="title">Subscribe to our newsletter!</h2>
            <p>Be the first to hear about our latest news, special offers and webinars!</p>
          </div>
        </div>

        <div className="site-info">
          <div className="copyright">
            <p>
              © 2023 All Rights Reserved Design by{' '}
              <a href="https://www.neting.hu/" target="_blank">
                Neting Informatika Ltd.
              </a>
            </p>
          </div>

          <ul className="site-info-menu">
            <li>
              <Link to="/#faq-anchor">FAQ</Link>
            </li>
            <li className="ml-3">
              <Link to="/privacy/">Privacy Policy - GDPR</Link>
            </li>
            <li className="ml-3">
              <Link to="/terms/">Terms Of Service</Link>
            </li>
            <li className="ml-3">
              <Link to="/imprint/">Imprint</Link>
            </li>
            <li className="ml-3">
              <CookiePreferences/>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
