import { useEffect } from 'react';

export function useScriptFromString (scriptString) {
  useEffect(() => {
    const script = document.createElement('script');
    script.textContent = scriptString
    script.async = true;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    }
  });
};

export function useScriptFromFile (url) {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    }
  }, [url]);
};
