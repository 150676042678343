import { Link } from 'gatsby';
import * as React from 'react';
import { withPrefix } from 'gatsby';
import { useAuth0 } from '@auth0/auth0-react';
import * as stripe from '../services/stripe-service';
import { A } from './a';

interface Props {
  dark?: boolean;
  home?: boolean;
}

const auth0Options = {
  audience: process.env.GATSBY_AUTH0_AUDIENCE,
  scope: process.env.GATSBY_AUTH0_SCOPE,
};

function Navbar({ dark, home }: Props) {
  const activeClassName = 'current_page';
  const [buttonsEnabled, setButtonsEnabled] = React.useState(true);
  const [hasSubscription, setHasSubscription] = React.useState(false);
  const [subscriptionInfoFetched, setSubscriptionInfoFetched] = React.useState(false);
  const [emailVerified, setEmailVerified] = React.useState(false);
  const [accessToken, setAccessToken] = React.useState('');
  const [sidebarVisible, setSidebarVisible] = React.useState(false);
  const [verificationInfoVisible, setVerificationInfoVisible] = React.useState(false);

  const {
    user,
    logout,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();

  const loadAccountInfo = React.useCallback(async () => {
    if (!isAuthenticated) {
      if (!isLoading) {
        setSubscriptionInfoFetched(true);
      }
      return;
    }
    const claims = await getIdTokenClaims(auth0Options);
    const token = await getAccessTokenSilently(auth0Options);
    setEmailVerified(!!claims?.email_verified);
    setVerificationInfoVisible(!emailVerified);
    setAccessToken(token);
    const subscribed = await stripe.hasSubscription(token);
    setHasSubscription(subscribed);
    setSubscriptionInfoFetched(true);
  }, [isAuthenticated, isLoading]);

  // const redirectToSubscription = React.useCallback(async () => {
  //   if (!buttonsEnabled) {
  //     return;
  //   }
  //   setButtonsEnabled(false);
  //   if (
  //     !emailVerified &&
  //     !confirm('Ön még nem erősítette meg az e-mail címét. Biztosan előfizet?')
  //   ) {
  //     setButtonsEnabled(true);
  //     return;
  //   }
  //   const subscriptionId = process.env.GATSBY_STRIPE_PRO_PRICE_ID || 'missing-price-id';
  //   const redirectUrl = await stripe.getSubscriptionUrl(accessToken, subscriptionId);
  //   window.location.assign(redirectUrl);
  // }, [emailVerified, accessToken]);

  const redirectToMySubscription = React.useCallback(async () => {
    if (!buttonsEnabled) {
      return;
    }
    setButtonsEnabled(false);
    const redirectUrl = await stripe.getMySubscriptionUrl(accessToken);
    window.location.assign(redirectUrl);
  }, [accessToken]);

  const onLoginClick = () => {
    if (!buttonsEnabled) {
      return;
    }
    setButtonsEnabled(false);
    loginWithRedirect();
  };

  const onLogoutClick = () => {
    if (!buttonsEnabled) {
      return;
    }
    setButtonsEnabled(false);
    logout({
      returnTo: process.env.GATSBY_AUTH0_CALLBACK_BASE_URI,
    });
  };

  React.useEffect(() => {
    console.log('useEffect');
    loadAccountInfo().catch(console.error);
  }, [loadAccountInfo]);

  React.useEffect(() => {
    document.documentElement.classList.toggle('sidebar-open', sidebarVisible);
    document.documentElement.classList.toggle('no-scroll', sidebarVisible);
  }, [sidebarVisible]);

  React.useEffect(() => {
    const listener = (e: PageTransitionEvent) => {
      if (e.persisted) {
        // Got here using the browser "Back" or "Forward" button.
        setButtonsEnabled(true);
      }
    };
    window.addEventListener('pageshow', listener, false);
    return () => window.removeEventListener('pageshow', listener);
  }, [setButtonsEnabled]);

  return (
    <header
      className={`site-header header_trans-fixed ${dark ? 'header-dark' : ''}`}
      data-top="992"
    >
      <div className="container">
        <div className="header-inner">
          <div
            className="toggle-menu"
            role={'button'}
            onClick={() => {
              setSidebarVisible(true);
            }}
          >
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>

          <div className="site-mobile-logo">
            <a href={withPrefix(`/`)} className="logo">
              <img
                src={
                  home
                    ? withPrefix(`assets/img/skillgo-logo-white-full.svg`)
                    : withPrefix(`assets/img/skillgo-logo.svg`)
                }
                alt="site logo"
                className="main-logo"
              />
              <img
                src={withPrefix('assets/img/skillgo-logo.svg')}
                alt="site logo"
                className="sticky-logo"
                style={{ display: `none` }}
              />
            </a>
          </div>
          <nav className={`site-nav ${dark ? 'nav-dark' : ''}`}>
            <div
              className="close-menu"
              role={'button'}
              onClick={() => {
                setSidebarVisible(false);
              }}
            >
              <span>Close</span>
              <i className="ei ei-icon_close"></i>
            </div>

            <div className="site-logo">
              <a href={withPrefix(`/`)} className="logo">
                <img
                  src={
                    home
                      ? withPrefix(`assets/img/skillgo-logo-white-full.svg`)
                      : withPrefix(`assets/img/skillgo-logo.svg`)
                  }
                  alt="site logo"
                  className="main-logo"
                />
                <img
                  src={withPrefix('assets/img/skillgo-logo.svg')}
                  alt="site logo"
                  className="sticky-logo"
                />
              </a>
            </div>
            <div className="menu-wrapper" data-top="992">
              <ul className="site-main-menu">
                <li>
                  <Link activeClassName={activeClassName} to="/">
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    activeClassName={activeClassName}
                    partiallyActive={true}
                    to="/getting-started/"
                  >
                    Getting Started
                  </Link>
                </li>
                <li>
                  <Link activeClassName={activeClassName} to="/pricing/">
                    Pricing
                  </Link>
                </li>
                <li>
                  <Link activeClassName={activeClassName} partiallyActive={true} to="/tutorial/">
                    Tutorial
                  </Link>
                </li>
                <li>
                  <Link activeClassName={activeClassName} partiallyActive={true} to="/templates/">
                    Templates
                  </Link>
                </li>
                <li>
                  <Link activeClassName={activeClassName} partiallyActive={true} to="/blog/">
                    Blog
                  </Link>
                </li>
                <li className="menu-item-has-children">
                  <A className="services">Service</A>
                  <ul className="sub-menu" style={{ padding: '23px 20px' }}>
                    <li>
                      <Link activeClassName={activeClassName} partiallyActive={true} to="/elearning-development/">
                        E-learning development
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* <li>
                  <Link activeClassName={activeClassName} partiallyActive={true} to="/workshop/">
                    Workshop
                  </Link>
                </li> */}
                {/* <li>
                    Workshop - coming soon!
                </li>                 */}

                {isLoading && !isAuthenticated && (
                  <li>
                    <A className="login">Login</A>
                  </li>
                )}

                {!isLoading && !isAuthenticated && (
                  <li>
                  <A className="login" onClick={() => onLoginClick()}>Login</A>
                  </li>
                )}

                {!isLoading && isAuthenticated && (
                  <li className="menu-item-has-children">
                    <A className="profile">Profile</A>
                    <ul className="sub-menu">
                      {user && user.email && (
                        <li>
                          <A className="no-hover"> {user.email}</A>
                        </li>
                      )}

                      {subscriptionInfoFetched && hasSubscription && (
                        <li>
                          <A onClick={() => redirectToMySubscription()}>Manage Plan</A>
                        </li>
                      )}

                      {(
                        <li>
                          <A onClick={() => onLogoutClick()}>Logout</A>
                        </li>
                      )}
                    </ul>
                  </li>
                )}
              </ul>

              <div className="nav-right">
                <Link className="nav-btn" to="/pricing/">
                  Try it now
                </Link>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
