import React, { useState } from 'react';
import { graphql, withPrefix } from 'gatsby';

import Navbar from '../components/navbar';
import Footer from '../components/footer';
import { Location } from '../models';
import EmailPopup from '../components/email-popup';
import RegistrationForm from '../components/registration-form';

interface Props {
  location: Location;
  data: {
    allConfig: {
      nodes: { name: string; value: string }[];
    };
  };
}

const Webinar = ({ data, location }: Props) => {
  return (
    <div id="main_content">
      <Navbar dark={true} />
      <EmailPopup />

      <section className="page-banner">
        <div className="container">
          <div className="page-title-wrapper">
            <h4 className="page-title">Webinar Registration</h4>
            <ul className="bradcurmed">
              <li>
                <a href={withPrefix("/")} rel="noopener noreferrer">
                  Home
                </a>
              </li>
              <li>Registration</li>
            </ul>
          </div>
        </div>

        <svg
          className="circle"
          data-parallax='{"x" : -200}'
          xmlns="http://www.w3.org/2000/svg"
          width="950px"
          height="950px"
        >
          <path
            fillRule="evenodd"
            stroke="rgb(250, 112, 112)"
            strokeWidth="100px"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            opacity="0.051"
            fill="none"
            d="M450.000,50.000 C670.914,50.000 850.000,229.086 850.000,450.000 C850.000,670.914 670.914,850.000 450.000,850.000 C229.086,850.000 50.000,670.914 50.000,450.000 C50.000,229.086 229.086,50.000 450.000,50.000 Z"
          />
        </svg>

        <ul className="animate-ball">
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
        </ul>
      </section>
      <section className="contact-us">
        <div className="container">
          <div className="row">
            <RegistrationForm usedClassName="col-md-12" dataForm="EIY29k"/>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Webinar;

// TEMPORARY BUILD FIX, THIS WAS THE ORIGINAL:
/* export const pageQuery = graphql`
 *   query {
 *     site {
 *       siteMetadata {
 *         title
 *       }
 *     }
 *     allConfig(filter: { name: { eq: "BACKEND_URL" } }) {
 *       nodes {
 *         name
 *         value
 *       }
 *     }
 *   }
 * `; */
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allConfig(filter: { name: { eq: "BACKEND_URL" } }) {
      nodes {
        name
        value
      }
    }
  }
`;
